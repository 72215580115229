import { ComponentPropsWithoutRef, FC } from "react";
import { ReactSVG } from "react-svg";
import Button from "./Button";
import { SelectOption } from "./OptionsSelect";

export type ClipDurationOption = "1m" | "auto";

type ClipDurationSelectorProps = {
  value: string;
  onSelectOption: (value: ClipDurationOption) => void;
} & ComponentPropsWithoutRef<"div">;

const clipDurationOptions: (SelectOption<ClipDurationOption> & {
  tooltip: string;
})[] = [
  {
    label: "Max. 1 minute",
    value: "1m",
    tooltip: "For YouTube Shorts",
  },
  {
    label: "Auto",
    value: "auto",
    tooltip: "For TikTok, Instagram Reels, etc.",
  },
];

const ClipDurationSelector: FC<ClipDurationSelectorProps> = ({
  value = "auto",
  onSelectOption,
  ...rest
}) => {
  return (
    <div {...rest} className={`${rest.className} flex gap-2`}>
      {clipDurationOptions.map((option) => {
        return (
          <Button
            key={option.value}
            size="m"
            className={`flex-1 ${
              value == option.value
                ? "!bg-slate-200 border-transparent"
                : "!text-slate-500 hover:bg-slate-100"
            }`}
            data-tooltip-content={option.tooltip}
            data-tooltip-id="global"
            variant={value == option.value ? "secondary" : "tertiary"}
            label={option.label}
            leading={
              option.value == "1m" ? (
                <ReactSVG
                  src="/svg/Shorts_logo.svg"
                  className={`w-5 h-5  ${
                    value == option.value ? "fill-slate-600" : "fill-slate-400"
                  }`}
                />
              ) : (
                <></>
              )
            }
            onClick={() => onSelectOption(option.value)}
          />
        );
      })}
    </div>
  );
};

export default ClipDurationSelector;
