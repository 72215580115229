import Link from "next/link";
import Logo from "./Logo";
import Nav from "./Nav";

interface HeaderProps {
  className?: string;
}

const Header = (props: HeaderProps) => {
  return (
    <header
      className={`${props.className} h-12 sticky top-0 py-2 flex gap-2 justify-center items-center w-full z-40 px-3 bg-slate-100`}
    >
      <Link href={"/"}>
        <Logo />
      </Link>

      <Nav className="ml-auto flex flex-wrap items-center justify-end" />
    </header>
  );
};

export default Header;
