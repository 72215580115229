import Image from "next/image";
import { useEffect, useState } from "react";
import { extractYouTubeThumbnail } from "../utils/youtube";

interface YouTubeThumbnailProps {
  videoId: string;
  className?: string;
}
const YouTubeThumbnail = (props: YouTubeThumbnailProps) => {
  const [src, setSrc] = useState("");
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    getYouTubeThumbnail();
  }, []);

  const getYouTubeThumbnail = async () => {
    const { url, width, height } = await extractYouTubeThumbnail(props.videoId);
    setSrc(url);
    setWidth(width);
    setHeight(height);
  };
  if (!src) return <div className={props.className} />;
  return (
    <Image
      width={width}
      height={height}
      className={`${props.className} aspect-video object-cover`}
      src={src}
      alt={"YouTube video thumbnail"}
      loading="eager"
    />
  );
};

export default YouTubeThumbnail;
