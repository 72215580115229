import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";

import {
  ArrowUpTrayIcon,
  SparklesIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import splitbee from "@splitbee/web";
import { useSession } from "@supabase/auth-helpers-react";
import { usePostHog } from "posthog-js/react";
import toast from "react-hot-toast";
import { ModalContext } from "../hooks/useModal";
import { useProfile } from "../hooks/useProfile";
import { validateFileVideoDuration } from "../utils/video";
import { extractYouTubeVideoId } from "../utils/youtube";
import Button from "./Button";
import Info from "./Info";
import { DISCORD_LINK } from "./Nav";
import NeedPaidPlan from "./NeedPaidPlan";
import SubmitVideoForm from "./SubmitVideo";

interface VideoInputProps {
  className?: string;
  disabled?: boolean;
}

const VideoInput = (props: VideoInputProps) => {
  // const [value, setValue] = useState(props.value);
  const urlInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showDropArea, setShowDropArea] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [value, setValue] = useState<string | null>(null);
  const modal = useContext(ModalContext);
  const posthog = usePostHog();
  const session = useSession();
  const { profile } = useProfile();

  const showVideoTooLongDialog = () => {
    if (!profile) return;

    const canUpgradePlan = profile.uploadDurationAllowanceSeconds < 7200;

    if (canUpgradePlan)
      return modal.open(
        <NeedPaidPlan
          plan={
            profile?.plan
              ? profile?.plan == "basic"
                ? "pro"
                : "basic"
              : "free"
          }
          variant="too_long_for_current_plan"
        />,
        {
          className: "max-w-6xl",
        }
      );

    return modal.open(
      <Info
        title="Video too long"
        description="We only support videos up to 2 hours long for now"
        ctaLabel="Need more? Let's talk!"
        onClickCta={() => {
          window.open(DISCORD_LINK, "_blank");
        }}
      />
    );
  };

  const handleSubmit = async (value?: string) => {
    if (!session) return toast.error("Please login first");

    const videoUrl = value ?? urlInputRef.current?.value;
    let videoId;
    // console.log("videoUrl", videoUrl);
    // console.log("value", value);

    if (videoUrl) {
      try {
        videoId = extractYouTubeVideoId(videoUrl);
        if (!videoId) throw new Error("No video ID found");
      } catch (error) {
        splitbee.track("Invalid YouTube URL");
        posthog?.capture("Invalid YouTube URL");
        return toast.error("Invalid YouTube video URL");
      }
      return modal.open(<SubmitVideoForm youtubeVid={videoId} />);
    }

    const videoFile = fileInputRef.current?.files?.[0];

    if (!videoFile) return toast.error("Please upload a video file");

    try {
      if (profile)
        await validateFileVideoDuration(
          videoFile,
          profile.uploadDurationAllowanceSeconds
        );
    } catch (error) {
      return showVideoTooLongDialog();
    }

    modal.open(<SubmitVideoForm file={videoFile} />);
  };

  // handle Submit on Enter
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const validateFileFormat = (file: File) => {
    const allowedFormats = ["video/mp4", "video/webm", "video/quicktime"];
    if (!allowedFormats.includes(file.type)) {
      posthog?.capture("Invalid file format", {
        file: file,
      });
      return false;
    }
    return true;
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const validFormat = validateFileFormat(event.target.files?.[0] as File);

    if (!validFormat) {
      clearInputs();
      return toast.error("Only mp4, webm and mov files are supported");
    }

    const file = event.target.files?.[0];
    if (!file) return;

    // If file > 10 gb
    if (file.size > 10 * 1024 * 1024 * 1024) {
      posthog?.capture("File too large", {
        size: file.size,
      });
      return toast.error("File too large (Max 10GB)");
    }

    setFile(file);
    handleSubmit();
  };

  const clearInputs = () => {
    setFile(null);
    if (fileInputRef.current) fileInputRef.current.value = "";

    setValue(null);
    if (urlInputRef.current) urlInputRef.current.value = "";
  };

  // Listen for keydown event
  useEffect(() => {
    urlInputRef.current?.addEventListener("keydown", handleKeyDown);
    return () => {
      urlInputRef.current?.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const FileItem = ({ file }: { file: File }) => {
    return (
      <div className="flex flex-1 items-center justify-start overflow-hidden">
        <span className="flex-1 text-left overflow-hidden text-ellipsis whitespace-nowrap">
          {file.name}
        </span>
      </div>
    );
  };

  const URLInput = (
    <input
      autoComplete="off"
      ref={urlInputRef}
      type="text"
      id="search-bar"
      placeholder={showDropArea ? "Drop!" : "Paste YouTube link or drop a file"}
      className={`pr-3 pl-0 py-2 w-full flex-1 outline-none bg-transparent ${
        showDropArea ? "placeholder:text-purple-500" : ""
      }`}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onPaste={(e) => {
        const youtubeVideoId = extractYouTubeVideoId(
          e.clipboardData.getData("text")
        );
        // console.log("youtubeVideoId", youtubeVideoId);
        if (youtubeVideoId) handleSubmit(e.clipboardData.getData("text"));
      }}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    />
  );

  const FileInput = (
    <input
      id="file-upload"
      className={`w-full h-full absolute inset-0 opacity-0 flex-1 text-start whitespace-nowrap text-ellipsis overflow-hidden ${
        showDropArea ? "text-purple-500" : ""
      } ${showDropArea ? "" : "hidden"}`}
      type="file"
      accept="video/mp4,video/quicktime,video/webm"
      onChange={handleFileChange}
      ref={fileInputRef}
      onDragLeave={() => {
        setShowDropArea(false);
      }}
    />
  );

  return (
    <label
      className={`${props.className}  ${
        showDropArea
          ? "bg-purple-200 border-purple-600 text-purple-600"
          : "bg-white"
      } relative flex flex-col sm:flex-row items^v-center justify-center border p-1.5 rounded-2xl gap-2`}
      htmlFor="search-bar"
      onDragEnter={() => {
        setShowDropArea(true);
      }}
      onDrop={() => setShowDropArea(false)}
    >
      <div className="flex items-center flex-1 w-full gap-2 overflow-hidden">
        <Button
          variant="tertiary"
          className={`${showDropArea ? "pointer-events-none" : ""}`}
          leading={
            <ArrowUpTrayIcon
              className={`w-5 h-5 ${
                showDropArea ? "text-purple-500" : "text-slate-500"
              }`}
            />
          }
          onClick={() => {
            fileInputRef.current?.click();
          }}
          data-tooltip-content={"Upload a video file"}
          data-tooltip-id="global"
        />

        {file ? <FileItem file={file} /> : URLInput}
        {FileInput}
        {(value || file) && (
          <Button
            size="m"
            variant="tertiary"
            leading={
              <XMarkIcon
                className={`w-5 h-5 ${
                  showDropArea ? "text-purple-500" : "text-slate-500"
                }`}
              />
            }
            onClick={clearInputs}
          />
        )}
      </div>
      <Button
        className={`w-full sm:w-auto ${
          showDropArea ? "pointer-events-none" : ""
        }`}
        disabled={props.disabled}
        onClick={() => handleSubmit()}
        label="Generate shorts"
        leading={<SparklesIcon className="w-3 h-3" />}
      />
    </label>
  );
};

export default VideoInput;
