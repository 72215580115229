export class APIError extends Error {
  url: string;
  statusCode: number;

  constructor(url: string, statusCode: number, message: string);
  constructor(response: Response, message?: string);

  // comme ca on peut faire new APIError("url", 404, "Not Found")
  // ou juste new APIError(res)
  constructor(
    urlOrResponse: string | Response,
    statusCodeOrMessage?: number | string,
    message?: string
  ) {
    if (typeof urlOrResponse === "string") {
      super(`${statusCodeOrMessage as number}: ${message}` || "API Error");
      this.url = urlOrResponse;
      this.statusCode = statusCodeOrMessage as number;
    } else {
      super(
        `${urlOrResponse.status}: ${message || urlOrResponse.statusText}` ||
          "API Error"
      );
      this.url = urlOrResponse.url;
      this.statusCode = urlOrResponse.status;
    }

    this.name = "APIError";

    // Set the prototype explicitly to allow for instanceof checks
    Object.setPrototypeOf(this, APIError.prototype);
  }
}
