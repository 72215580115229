export const PLANS = {
  free: {
    key: "free",
    label: "Free",
    maxVideo: 1,
    maxClip: 10,
  },
  basic: {
    key: "basic",
    label: "Basic",
    maxVideo: 10,
    maxClip: 100,
  },
  pro: {
    key: "pro",
    label: "Pro",
    maxVideo: 30,
    maxClip: 300,
  },
  enterprise: {
    key: "enterprise",
    label: "Enterprise",
    maxVideo: 30,
    maxClip: 300,
  },
};
