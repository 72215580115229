const apiKey = process.env.NEXT_PUBLIC_YOUTUBE_API_KEY;

export function extractVideoData(videoId: string) {
  // construct the API URL
  const apiUrl = `https://www.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails&id=${videoId}&key=${process.env.NEXT_PUBLIC_YOUTUBE_API_KEY}`;

  // fetch the video data from the YouTube API
  fetch(apiUrl)
    .then((response) => response.json())
    .then((data) => {
      // extract the thumbnail, title, and duration from the video data
      const thumbnailUrl = data.items[0].snippet.thumbnails.medium.url;
      const title = data.items[0].snippet.title;
      const duration = data.items[0].contentDetails.duration;
    })
    .catch((error) => {
      console.error(`Error fetching video data: ${error}`);
    });
}

interface ThumbnailInfo {
  url: string;
  width: number;
  height: number;
}

export function extractYouTubeThumbnail(
  videoId: string,
  resolution: string = "hqdefault"
): Promise<ThumbnailInfo> {
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/${resolution}.jpg`;
  return new Promise<ThumbnailInfo>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      resolve({
        url: thumbnailUrl,
        width: width,
        height: height,
      });
    };
    img.onerror = () => {
      reject(new Error(`Failed to load thumbnail for video ID ${videoId}`));
    };
    img.src = thumbnailUrl;
  });
}

export async function isVideoAvailableInUS(videoId: string) {
  const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=contentDetails&key=${apiKey}`;

  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error("Network error while fetching video details");
    }

    const data = await response.json();
    const video = data.items[0];

    if (!video) {
      console.error("Video not found");
      return false;
    }

    const regionRestriction = video.contentDetails.regionRestriction;

    if (regionRestriction) {
      const { allowed, blocked } = regionRestriction;

      if (allowed && allowed.length > 0 && !allowed.includes("US")) {
        return false;
      }

      if (blocked && blocked.includes("US")) {
        return false;
      }
    }

    return true;
  } catch (error: any) {
    console.error("Error:", error.message);
    return false;
  }
}

export async function fetchVideoSource(videoId: string) {
  const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=player&key=${apiKey}`;
  try {
    const response = await fetch(apiUrl);
    const data = await response.json();
    const videoInfo = data.items[0];

    if (videoInfo) {
      const embedHtml = videoInfo.player.embedHtml;
      const videoUrl = embedHtml.match(/src="(.*?)"/)[1];
      return videoUrl;
    } else {
      console.error("Video not found");
    }
  } catch (error) {
    console.error(error);
  }
}

export function getYoutubeVideoLink(videoId: string) {
  return `https://www.youtube.com/watch?v=${videoId}`;
}

export function extractYouTubeVideoId(url: string): string | null {
  // Regular expression to match various forms of YouTube URLs including shortened URLs
  const regex =
    /(youtu.be\/|youtube.com\/(watch\?v=|embed\/|v\/|shorts\/))([^?&"'>]+)/;

  const match = regex.exec(url);

  // If it's a YouTube URL
  if (match && match[3]) {
    // Check if it's a valid video ID format (11 characters)
    if (match[3].length === 11) {
      return match[3];
    } else {
      throw new Error("Invalid YouTube URL");
    }
  }

  // If it's not a YouTube URL at all
  if (!url.includes("youtube") && !url.includes("youtu.be")) {
    return null;
  }

  // If it's an invalid YouTube URL
  throw new Error("Invalid YouTube URL");
}
