import { APIError } from "@/common/errors/api_error";
import { CheckCircleIcon, SparklesIcon } from "@heroicons/react/20/solid";
import {
  ChatBubbleBottomCenterIcon,
  CheckIcon,
  ChevronDownIcon,
  FaceSmileIcon,
  FilmIcon,
  InformationCircleIcon,
  ViewfinderCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import splitbee from "@splitbee/web";
import { useSession } from "@supabase/auth-helpers-react";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocalStorage } from "usehooks-ts";
import { WHISPER_LANGUAGES } from "../const/languageConst";
import { PLANS } from "../const/planConst";
import useFileUpload from "../hooks/useFileUpload";
import { ModalContext } from "../hooks/useModal";
import { useProfile } from "../hooks/useProfile";
import { WordTimecode } from "../types/player";
import { capitalizeFirstLetter } from "../utils/string";
import { readVideoFileMetadata } from "../utils/video";
import { getYoutubeVideoLink } from "../utils/youtube";
import Button from "./Button";
import ClipDurationSelector, {
  ClipDurationOption,
} from "./ClipDurationSelector";
import CustomTooltip from "./CustomTooltip";
import Info from "./Info";
import { DISCORD_LINK } from "./Nav";
import { default as NeedPaidPlan } from "./NeedPaidPlan";
import OptionsSelect from "./OptionsSelect";
import PropertySection from "./PropertySection";
import Spinner from "./Spinner";
import Typography from "./Typography";
import YouTubeThumbnail from "./YouTubeThumbnail";

const languageOptions: {
  label: string;
  value: WhisperLanguage | "default";
}[] = [
  {
    label: "Auto",
    value: "default",
  },
  ...(Array.from(Object.keys(WHISPER_LANGUAGES)).map((key) => {
    return {
      label: capitalizeFirstLetter(
        WHISPER_LANGUAGES[key as keyof typeof WHISPER_LANGUAGES]
      ),
      value: key,
    };
  }) as {
    label: string;
    value: WhisperLanguage | "default";
  }[]),
];

const translateOptions: {
  label: string;
  value: WhisperLanguage | "default";
}[] = [
  {
    label: "No translation",
    value: "default",
  },
  {
    label: "English",
    value: "en",
  },
];

const maxDurationOptions: {
  label: string;
  value: "default" | "1min";
}[] = [
  {
    label: "Auto",
    value: "default",
  },
  {
    label: "1 minute",
    value: "1min",
  },
];

export interface WordsTimecodesTranscription {
  word_timecodes: WordTimecode[];
  transcript: string;
}

export type VideoCut = [number, number];
export type VideoCuts = VideoCut[];

export interface DetectedFaces {
  width: number;
  height: number;
  frame_count: number;
  fps: number;
  frames: { frame: number; face_locations: FaceLocation[] }[];
}

export type FaceLocation = [number, number, number, number];

export type CropRange = {
  start_frame: number;
  end_frame: number;
  crop_position: [number, number];
};

export type VideoErrorCode =
  | "too_many_tokens"
  | "not_enough_tokens"
  | "no_clips_generated"
  | "internal";

interface SubmitVideoFormProps {
  className?: string;
  youtubeVid?: string;
  file?: File;
}

type WhisperLanguage = keyof typeof WHISPER_LANGUAGES;

const serializeMaxDuration: {
  [key in ClipDurationOption]: number | undefined;
} = {
  "1m": 60,
  auto: undefined,
};

enum LoadingState {
  IDLE = 0,
  UPLOADING = 1,
  LOADING = 2,
}

// export const VIDEOS_DATA_STORAGE_KEY = "videos_data";

const SubmitVideoForm = (props: SubmitVideoFormProps) => {
  // const { updateVideoData } = useVideoData();
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.IDLE
  );

  const [trialCode, setTrialCode] = useLocalStorage("trial_code", "");
  const [translationLanguage, setTranslationLanguage] = useState<
    WhisperLanguage | "default"
  >("default");
  const [videoLanguage, setVideoLanguage] = useState<
    WhisperLanguage | "default"
  >("default");
  const [detectedLanguage, setDetectedLanguage] = useState<
    WhisperLanguage | undefined
  >(undefined);
  const [clipDuration, setClipDuration] = useState<ClipDurationOption>("auto");
  const [ytVideoDetailsLoading, setYtVideoDetailsLoading] = useState(false);
  const [videoDuration, setVideoDuration] = useState<number>();
  const [videoDimensions, setVideoDimensions] = useState<{
    width: number;
    height: number;
  }>();
  const [fileSrc, setFileSrc] = useState<string | undefined>();
  const router = useRouter();
  const modal = useContext(ModalContext);
  const session = useSession();
  const posthog = usePostHog();
  const { profile } = useProfile();
  const { uploadFile, uploadProgress, error } = useFileUpload();
  const [selectedEditOptions, setSelectedEditOptions] = useState<string[]>([
    "cuts",
    "crops",
    "captions",
    "emojis",
  ]);

  useEffect(() => {
    if (!videoDuration) return;

    if (videoDuration < 60)
      setSelectedEditOptions(["crops", "captions", "emojis"]);
  }, [videoDuration]);

  useEffect(() => {
    if (!videoDimensions) return;

    console.log(videoDimensions);

    // Check if video is vertical
    if (videoDimensions.height > videoDimensions.width)
      setSelectedEditOptions(["captions", "emojis"]);
  }, [videoDimensions]);

  useEffect(() => {
    if (!props.file) detectLanguage();
    if (props.file) {
      previewFile(props.file);
      detectMetadata(props.file);
    }
  }, []);

  function previewFile(file: File) {
    const url = URL.createObjectURL(file);

    setFileSrc(url);
  }
  const detectMetadata = async (file: File) => {
    const videoMetadata = await readVideoFileMetadata(file);
    if (!videoMetadata) return;
    setVideoDuration(videoMetadata.duration);
    setVideoDimensions({
      width: videoMetadata.videoWidth,
      height: videoMetadata.videoHeight,
    });
  };
  const detectLanguage = async () => {
    try {
      setYtVideoDetailsLoading(true);
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/yt-video-details?id=${props.youtubeVid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      const resJson = await res.json();
      // console.log(resJson);
      if (!resJson.language) return;
      setDetectedLanguage(resJson.language);
      setVideoLanguage(resJson.language);
      setVideoDuration(resJson.duration_seconds);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setYtVideoDetailsLoading(false);
    }
  };

  const handleSubmit = async () => {
    let videoUrl;
    try {
      if (props.file) {
        setLoadingState(LoadingState.UPLOADING);
        const uploadedUrl = await uploadFile(props.file);
        videoUrl = uploadedUrl;
      }

      if (
        videoDuration &&
        videoDuration > 360 &&
        !selectedEditOptions.includes("cuts")
      )
        return toast.error(
          `Please select "Edit shorts" for videos longer than 6 minutes`
        );
      setLoadingState(LoadingState.LOADING);

      if (props.youtubeVid) videoUrl = getYoutubeVideoLink(props.youtubeVid);

      if (!videoUrl) throw new Error("Something went wrong (no video url)");

      await handleNewVideo(videoUrl);

      splitbee.track("Submit video", { type: props.file ? "file" : "youtube" });
      posthog?.capture("Submit video", {
        type: props.file ? "file" : "youtube",
      });
    } catch (error) {
      posthog?.capture("Submit video error", {
        message: (error as Error).message,
        status: error instanceof APIError ? error.statusCode : undefined,
        url: error instanceof APIError ? error.url : undefined,
      });
      toast.error((error as Error).message);
    } finally {
      setLoadingState(LoadingState.IDLE);
    }
  };

  const handleNewVideo = async (srcVideoUrl: string) => {
    try {
      const videosRes = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/videos`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session?.access_token}`,
          },
          body: JSON.stringify({
            youtube_vid: props.youtubeVid,
            source_video_url: srcVideoUrl,
            name: props.file?.name,
            language: videoLanguage == "default" ? undefined : videoLanguage,
            translate_to:
              translationLanguage == "default"
                ? undefined
                : translationLanguage,
            max_duration: serializeMaxDuration[clipDuration],
            trial_code: trialCode,
            editing_options: {
              edit: selectedEditOptions.includes("cuts"),
              reframe: selectedEditOptions.includes("crops"),
              captions: selectedEditOptions.includes("captions"),
              emojis: selectedEditOptions.includes("emojis"),
            },
          }),
        }
      );

      const resJson = await videosRes.json();

      const videoTooLong =
        videosRes.status === 422 && resJson.error_code == "video_too_long";
      const videoTooLongForCurrentPlan =
        videosRes.status == 402 && resJson.error_code == "video_too_long";
      const needPaidPlan = videosRes.status == 402 && !profile?.plan;
      const needUpgradePlan =
        videosRes.status == 402 && profile?.plan == PLANS.basic.key;
      // ici il manque le cas ou plan = pro mais 402 quand meme (si le gars utilise ses 30 vids)

      if (videoTooLong) {
        splitbee.track("Too long Dialog Showed");
        posthog?.capture("Too long Dialog Showed", {
          video_duration: resJson.video_duration,
        });
        return modal.open(
          <Info
            title="Video too long"
            description="We only support videos up to 2 hours long for now"
            ctaLabel="Need more? Let's talk!"
            onClickCta={() => {
              window.open(DISCORD_LINK, "_blank");
            }}
          />
        );
      } else if (videoTooLongForCurrentPlan) {
        splitbee.track("Pro Plan Dialog Showed (video too long)");
        posthog?.capture("Pro Plan Dialog Showed (video too long)", {
          video_duration: resJson.video_duration,
        });
        return modal.open(
          <NeedPaidPlan
            plan={profile?.plan ?? "free"}
            variant="too_long_for_current_plan"
          />,
          {
            className: "max-w-6xl",
          }
        );
      } else if (needPaidPlan) {
        splitbee.track("Paid Plan Dialog Showed");
        posthog?.capture("Paid Plan Dialog Showed");
        return modal.open(<NeedPaidPlan plan="free" />, {
          className: "max-w-6xl",
        });
      } else if (needUpgradePlan) {
        splitbee.track("Upgrade Plan Dialog Showed");
        posthog?.capture("Upgrade Plan Dialog Showed");
        return modal.open(<NeedPaidPlan plan="basic" />, {
          className: "max-w-6xl",
        });
      } else if (videosRes.status == 422) {
        splitbee.track(resJson.message);
        posthog?.capture(resJson.message);
        return toast.error(resJson.message);
      } else if (videosRes.status >= 400) {
        throw new APIError(videosRes);
      }

      modal.close();
      router.push(`/videos/${resJson.id}`);
    } catch (error: any) {
      throw error;
    }
  };

  const options = [
    {
      name: "Edit shorts",
      value: "cuts",
      icon: <FilmIcon className="w-4 h-4" />,
      tooltip: "Create up to 10 shorts from your video",
    },
    {
      name: "Crop",
      value: "crops",
      icon: <ViewfinderCircleIcon className="w-4 h-4" />,
      tooltip: "Crop your video to focus one the important parts",
    },
    {
      name: "Add Captions",
      value: "captions",
      icon: <ChatBubbleBottomCenterIcon className="w-4 h-4" />,
      tooltip: "Add engaging captions to your video",
    },
    {
      name: "Add Emojis",
      value: "emojis",
      icon: <FaceSmileIcon className="w-4 h-4" />,
      tooltip: "Add relevant emojis to your video",
    },
  ];

  const handleSelectOption = (value: string) => {
    if (!selectedEditOptions) {
      setSelectedEditOptions([value]);
      return;
    }

    if (selectedEditOptions.includes(value)) {
      setSelectedEditOptions(selectedEditOptions.filter((v) => v != value));
      return;
    }

    setSelectedEditOptions([...selectedEditOptions, value]);
  };

  return (
    <section className={`${props.className}`}>
      <Typography variant="h5">New video</Typography>

      <Typography variant="subtitle2" className="mt-4 block text-slate-600">
        Klap will edit this video:
      </Typography>

      {props.youtubeVid ? (
        <YouTubeThumbnail
          className="rounded-xl w-48 shadow-xl mt-4 object-cover"
          videoId={props.youtubeVid}
        />
      ) : fileSrc ? (
        <video
          className="rounded-xl w-48 shadow-xl mt-4"
          src={fileSrc}
          muted
          controls={false}
        />
      ) : (
        <></>
      )}
      <PropertySection
        title="What do you want Klap AI to do?"
        heading
        className="mt-8 !gap-0"
      >
        <div className="flex gap-2 flex-wrap">
          {options.map((option) => {
            return (
              <Button
                data-tooltip-content={option.tooltip}
                data-tooltip-id="dialog"
                // leading={option.icon}
                size="m"
                className={`${
                  selectedEditOptions.includes(option.value)
                    ? "!bg-slate-200"
                    : "!text-slate-500  hover:bg-slate-100"
                }`}
                key={option.value}
                label={option.name}
                onClick={() => handleSelectOption(option.value)}
                variant={
                  selectedEditOptions.includes(option.value)
                    ? "secondary"
                    : "tertiary"
                }
                leading={
                  selectedEditOptions.includes(option.value) ? (
                    <CheckCircleIcon className="w-4 h-4 text-slate-600" />
                  ) : (
                    <div className="w-4 h-4 rounded-full flex items-center justify-center">
                      <div className="w-3 h-3 rounded-full border border-slate-300" />
                    </div>
                  )
                }
              />
            );
          })}
        </div>
      </PropertySection>

      {/* <details className="flex-1 group">
        <summary className="flex items-center gap-2 mt-8 border-b border-b-slate-200 py-2 cursor-pointer">
          <div className="flex">
            <Typography
              className="flex items-center text-slate-700 mx-2"
              variant="subtitle3"
            >
              AI editing
              <span className="group-open:invisible">
                {AIEditingLabel ? `: ${AIEditingLabel}` : ``}
              </span>
            </Typography>
          </div>

          <ChevronDownIcon className="h-4 w-4 ml-auto group-open:rotate-180 text-slate-500 mx-2" />
        </summary>

        <PropertySection
          className="mt-8"
          title="Cuts"
          trailing={
            <CheckboxInput
              checked={cuts}
              onValueChange={() => setCuts(!cuts)}
            />
          }
        >
          <Typography variant="caption" className="text-slate-500 px-2">
            Automatically cut your video into 10 shorts
          </Typography>
        </PropertySection>
        <PropertySection
          className="mt-8"
          title="Crops"
          trailing={
            <CheckboxInput
              checked={crops}
              onValueChange={() => setCrops(!crops)}
            />
          }
        >
          <Typography variant="caption" className="text-slate-500 px-2">
            Automatically crop your video to fit the screen
          </Typography>
        </PropertySection>
        <PropertySection
          className="mt-8"
          title="Captions"
          trailing={
            <CheckboxInput
              checked={captions}
              onValueChange={() => setCaptions(!captions)}
            />
          }
        >
          <Typography variant="caption" className="text-slate-500 px-2">
            Automatically add captions to your video
          </Typography>
        </PropertySection>
        <PropertySection
          className="mt-8"
          title="Emojis"
          trailing={
            <CheckboxInput
              checked={emojis}
              onValueChange={() => setEmojis(!emojis)}
            />
          }
        >
          <Typography variant="caption" className="text-slate-500 px-2">
            Automatically add emojis to your video
          </Typography>
        </PropertySection>
      </details> */}
      <details className="flex-1 group">
        <summary className="flex items-center gap-2 mt-8 border-b border-b-slate-200 py-2 cursor-pointer">
          <div className="flex">
            <Typography
              className="flex items-center text-slate-700 mx-2"
              variant="subtitle3"
            >
              Language:
              {ytVideoDetailsLoading ? (
                <span className="flex items-center">
                  <span className="text-slate-500 ml-1">
                    Detecting language
                  </span>
                  <Spinner className="w-2 h-2 ml-1" />
                </span>
              ) : (
                <>
                  <span className="ml-1">
                    {translationLanguage != "default"
                      ? capitalizeFirstLetter(
                          WHISPER_LANGUAGES[translationLanguage]
                        )
                      : videoLanguage != "default"
                      ? capitalizeFirstLetter(WHISPER_LANGUAGES[videoLanguage])
                      : "Auto"}
                  </span>
                  {(detectedLanguage == videoLanguage ||
                    videoLanguage == undefined) && (
                    <SparklesIcon className="w-3 h-3 ml-1" />
                  )}
                </>
              )}
            </Typography>
          </div>
          <ChevronDownIcon className="h-4 w-4 ml-auto group-open:rotate-180 text-slate-500 mx-2" />
        </summary>
        <div className="flex gap-2 mt-8">
          <PropertySection title="Original" className="flex-1">
            <OptionsSelect
              options={languageOptions}
              value={videoLanguage}
              onSelectOption={(l) => setVideoLanguage(l as WhisperLanguage)}
              loading={ytVideoDetailsLoading}
              loadingLabel="Detecting language"
            />
          </PropertySection>
          <PropertySection title="Translation" className="flex-1">
            <OptionsSelect
              options={translateOptions}
              value={translationLanguage}
              onSelectOption={(l) =>
                setTranslationLanguage(l as WhisperLanguage)
              }
            />
            {translationLanguage != "default" && (
              <Typography
                variant="caption"
                className="text-slate-500 mt-2 px-2"
              >
                Will apply to captions only
              </Typography>
            )}
          </PropertySection>
        </div>
      </details>
      {selectedEditOptions.includes("cuts") && (
        <PropertySection className="mt-8" title="Duration">
          <ClipDurationSelector
            value={clipDuration}
            onSelectOption={(v) => setClipDuration(v)}
          />
        </PropertySection>
      )}
      <div className="sticky bottom-6 bg-white">
        <Typography
          variant="caption"
          className="mt-16 flex items-center gap-1 text-slate-700"
        >
          <InformationCircleIcon className="w-4 h-4" />{" "}
          <span>
            Klap works best with{" "}
            <span
              className="underline"
              data-tooltip-id="video-validation-tooltip"
            >
              speech-based videos
            </span>
          </span>
        </Typography>
        <Button
          loading={loadingState == LoadingState.LOADING}
          loadingLabel={"Verifying"}
          disabled={loadingState != LoadingState.IDLE}
          leading={
            loadingState == LoadingState.UPLOADING && (
              <Typography
                variant="overline"
                className="text-slate-500 w-6 font-semibold"
              >
                {Math.round(uploadProgress)}%
              </Typography>
            )
          }
          loadingProgress={
            loadingState == LoadingState.UPLOADING ? uploadProgress : undefined
          }
          onClick={handleSubmit}
          className="mt-4"
          label={
            loadingState == LoadingState.UPLOADING ? "Uploading" : "Generate"
          }
        />
      </div>
      <CustomTooltip place="bottom" id="video-validation-tooltip">
        <div className="text-start">
          <div>
            <span className="flex items-center gap-2">
              <CheckIcon className="w-3 h-3" />
              <Typography className="" variant="caption">
                Podcasts
              </Typography>
            </span>
            <span className="flex items-center gap-2">
              <CheckIcon className="w-3 h-3" />
              <Typography className="" variant="caption">
                Interviews
              </Typography>
            </span>
            <span className="flex items-center gap-2">
              <CheckIcon className="w-3 h-3" />
              <Typography className="" variant="caption">
                Educational videos
              </Typography>
            </span>
            <span className="flex items-center gap-2">
              <CheckIcon className="w-3 h-3" />
              <Typography className="" variant="caption">
                ...all videos including speech
              </Typography>
            </span>
          </div>
          <div className="mt-2 opacity-70">
            <span className="flex items-center gap-2">
              <XMarkIcon className="w-3 h-3" />
              <Typography className="" variant="caption">
                Music videos
              </Typography>
            </span>
          </div>
        </div>
      </CustomTooltip>
    </section>
  );
};

export default SubmitVideoForm;
