import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import posthog from "posthog-js";
import { useCallback, useState } from "react";

type UseFileUploadReturn = {
  uploadProgress: number;
  uploadFile: (file: File) => Promise<string>;
  error: string | null;
};

const useFileUpload = (): UseFileUploadReturn => {
  const session = useSession();
  const supabaseClient = useSupabaseClient();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  const getValidAccessToken = useCallback(async () => {
    if (!session) throw new Error("Log in and try again.");
    if (session.expires_at && session.expires_at < Date.now() / 1000) {
      const refreshedSession = await supabaseClient.auth.refreshSession(
        session
      );
      if (refreshedSession) return refreshedSession.data.session?.access_token;
      else throw new Error("Access token has expired. Please log in again.");
    }
    return session.access_token;
  }, [session, supabaseClient.auth]);

  const uploadFileRequest = async (url: string, file: File): Promise<void> => {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();

      // Error event listener
      request.addEventListener("error", () => {
        const errorMessage = `Error during the file upload. Please try again. File: ${
          file.name
        } - ${file.type} - ${
          file.size / 1024 / 1024
        } MB - ${uploadProgress}% uploaded`;

        console.error(errorMessage);
        reject(new Error(errorMessage));
      });

      // Upload progress event
      request.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
          const percentCompleted = (e.loaded / e.total) * 100;
          setUploadProgress(percentCompleted);
        } else {
          posthog.capture("Media Upload Length Not Computable");
          setUploadProgress(0);
        }
      });

      // Request finished event
      request.addEventListener("load", () => {
        if (request.status >= 200 && request.status < 300) {
          resolve();
        } else {
          const errorMessage = `Server returned an error status: ${request.status}`;
          console.error(errorMessage);
          reject(new Error(errorMessage));
        }
      });

      request.open("PUT", url);
      request.setRequestHeader("Content-Type", file.type);

      // Send the file directly in the body of the request
      request.send(file);
    });
  };

  const uploadFile = async (file: File): Promise<string> => {
    if (!session) throw new Error("User is not logged in");
    try {
      const postMediaUploadRes = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/media/upload`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getValidAccessToken()}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mimetype: file.type,
          }),
        }
      );
      if (!postMediaUploadRes.ok)
        throw new Error(
          `Error in postMediaUploadRes. Reason ${await postMediaUploadRes.text()}`
        );
      const postMediaUploadResJson = await postMediaUploadRes.json();

      await uploadFileRequest(postMediaUploadResJson.signed_upload_url, file);

      const uploadSignedUrlRes = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/media/upload?filename=${postMediaUploadResJson.filename}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${await getValidAccessToken()}`,
          },
        }
      );

      if (!uploadSignedUrlRes.ok)
        throw new Error(
          `Error in uploadSignedUrlRes. Reason ${await uploadSignedUrlRes.text()}`
        );
      const { signed_read_url } = await uploadSignedUrlRes.json();

      return signed_read_url;
    } catch (error) {
      console.error(error);
      posthog.capture("Media Upload Error", {
        error: (error as Error).message,
        online: navigator.onLine,
      });
      // Check if the error is an instance of Error and has a message property
      if (error instanceof Error) {
        setError(error.message);
      } else {
        // If the error is not an Error instance, handle it as a generic error
        setError("An unknown error occurred");
      }
      throw error;
    }
  };

  return { uploadProgress, uploadFile, error };
};

export default useFileUpload;
